import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const classNames = require('classname')
const styles = require('../styles/terms.module.css')


const NotFoundPage: React.FC = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className={styles.container}>
            <div className={classNames({ [styles.main]: true, [styles.mainRaised]: true })}>
              <div className={styles.mainPadding}>
                <h1>404 Page not found</h1>
                <div className={styles.space150}></div>
              </div>
            

            </div>
            </div>
    
  </Layout>
)

export default NotFoundPage
